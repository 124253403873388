<template>
	<div id="changepwd">
		<el-form :model="registerForm" :rules="rules" ref="registerForm" label-position="top">
			<p class="StepTitle">修改密码 </p>
			<el-form-item label="手机号" prop="phoneNumber">
				<el-input clearable disabled v-model="registerForm.phoneNumber" placeholder="请输入手机号" maxlength="11">
				</el-input>
			</el-form-item>
			<el-form-item label="验证码" prop="verifyCode">
				<el-input clearable v-model="registerForm.verifyCode" maxlength="6" placeholder="请输入验证码">
					<el-button :disabled="disabled" slot="append" @click="sendCodeFun()">{{sendCodeText}}</el-button>
				</el-input>
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input clearable show-password v-model="registerForm.password" placeholder="请输入密码"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="repeatPassword">
				<el-input clearable show-password v-model="registerForm.repeatPassword"
					placeholder="请输入确认密码"></el-input>
			</el-form-item>
			<el-form-item>
				<div class="options">
					<el-button class="bank" @click="bank()">返回</el-button>
					<el-button class="next" @click="onSubmit('registerForm')">确认修改</el-button>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	import {
		getDoctorInfo,
		sendCode,
		changePassword,
		getAssistantInfo,
		changeAssistantPwd
	} from '@/request/api.js';
	export default {
		name: 'my',
		components: {},
		data() {
			return {
				checked: true,
				seconds: 60,
				disabled: false,
				sendCodeText: '获取验证码',
				registerForm: {
					phoneNumber: '',
					verifyCode: '',
					password: '',
					repeatPassword: ''
				},
				rules: {
					phoneNumber: [{
						required: true,
						message: '请输入手机号',
						trigger: 'change'
					}, {
						validator: (rule, value, callback) => {
							if (!/^1[3-9]\d{9}$/.test(value)) {
								callback(new Error('请输入正确的手机号!'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}],
					verifyCode: [{
						required: true,
						message: '请输入验证码',
						trigger: 'change'
					}, {
						min: 4,
						max: 6,
						message: '请输入正确的验证码格式',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'change'
					}, {
						min: 4,
						message: '请至少输入6位字符的密码',
						trigger: 'blur'
					}],
					repeatPassword: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'change'
					}, {
						validator: (rule, value, callback) => {
							if (value !== this.registerForm.password) {
								callback(new Error('两次输入密码不一致!'));
							} else {
								callback();
							}
						},
						trigger: 'change'
					}]
				}
			}
		},
		created() {
			this.getDocInfo();
		},
		mounted() {},
		methods: {
			getDocInfo() {
				if (this.$route.query.status == "d") {
					getDoctorInfo().then(res => {
						if (res.code == 200) {
							this.registerForm.phoneNumber = res.data.phoneNumber;
						};
					});
				} else {
					getAssistantInfo().then(res => {
						if (res.code == 200) {
							this.registerForm.phoneNumber = res.data.phoneNumber;
						};
					});
				};
			},
			// 获取验证码
			sendCodeFun() {
				if (!this.registerForm.phoneNumber) {
					this.$notify({
						title: '提示',
						type: 'warning',
						message: '请输入手机号',
						position: 'bottom-right'
					});
					return false;
				} else if (!/^1[3-9]\d{9}$/.test(this.registerForm.phoneNumber)) {
					this.$notify({
						title: '提示',
						type: 'warning',
						message: '请输入正确的手机号',
						position: 'bottom-right'
					});
					return false;
				} else {
					sendCode(this.registerForm.phoneNumber)
						.then(res => {
							if (res.code == 200) {
								this.countDown();
								this.$notify({
									title: '提示',
									type: 'success',
									message: '获取验证码成功',
									position: 'bottom-right'
								});
							} else {
								this.$notify({
									title: '错误',
									type: 'error',
									message: res.msg,
									position: 'bottom-right'
								});
							};
						});
				};
			},
			// 倒计时
			countDown() {
				this.disabled = true;
				this.sendCodeText = `${this.seconds}秒后重新获取`;
				if (this.seconds > 0) {
					this.seconds--;
					setTimeout(() => {
						this.countDown(this.seconds)
					}, 1000);
				} else {
					this.sendCodeText = '再次获取';
					this.disabled = false;
					this.seconds = 60;
				};
			},
			agreement() {
				console.log(1);
			},
			onSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.$route.query.status == "d") {
							changePassword(this.registerForm).then(res => {
								if (res.code == 200) {
									this.$notify({
										title: '提示',
										type: 'success',
										message: '修改密码成功',
										position: 'bottom-right'
									});
									this.$router.go(-1);
								} else {
									this.$notify({
										title: '错误',
										type: 'error',
										message: res.msg,
										position: 'bottom-right'
									});
								};
							});
						} else {
							changeAssistantPwd(this.registerForm).then(res => {
								if (res.code == 200) {
									// localStorage.setItem('regToken', JSON.stringify(res.data));
									this.$notify({
										title: '提示',
										type: 'success',
										message: '修改密码成功',
										position: 'bottom-right'
									});
									this.$router.go(-1);
								} else {
									this.$notify({
										title: '错误',
										type: 'error',
										message: res.msg,
										position: 'bottom-right'
									});
								};
							});
						};
					} else {
						console.log('error submit!!');
						return false;
					};
				});
			},
			bank() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style lang="scss" scoped>
	#changepwd {
		padding: 20px;
		height: 100%;

		.StepTitle {
			width: 100%;
			font-size: 22px;
			color: #112950;
			// border-bottom: 1px solid #E5E5E5;
			padding-bottom: 10px;
			margin-bottom: 20px;
		}

		::v-deep .el-form {
			width: 600px;
			height: 100%;
			padding: 50px;
			// margin: 20px 0;
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
			border-radius: 10px;

			.el-form-item__label {
				padding: 0;
				line-height: 1;
				color: #112950;
			}

			.el-input__inner {
				border: none;
				border-bottom: 1px solid #E5E5E5;
				border-radius: 0;
			}

			.el-input-group__append {
				background-color: #fff;
				border: none;
				border-bottom: 1px solid #E5E5E5;
			}

			.el-checkbox {
				display: flex;
				align-items: center;
			}

			.options {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;

				.el-button {
					width: 300px;
					height: 50px;

				}

				.next {
					background-color: #007AFF;
					color: #fff;
				}

				.back {}
			}
		}

	}
</style>